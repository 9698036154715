// mixins

@mixin background-cover() {
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin centering-the-unknown($childSelector, $childMaxWidth: null) {
    text-align: center;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing */
    }

    #{$childSelector} {
        display: inline-block;
        vertical-align: middle;
        width: 100%;

        @if $childMaxWidth {
            max-width: $childMaxWidth;
        }
    }
}

@mixin span-parent() {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

@function get_bootstrap_colors() {
    $bootstrap-colors: (
        'blue': $blue,
        'green': $green,
        'orange': $orange,
        'pink': $pink,
        'purple': $purple,
        'red': $red,
        'yellow': $yellow,
        'teal': $teal,
        'white': $white,
        'black': $black,
        'gray': $gray,
        'gray-dark': $gray-dark,
        'gray-light': $gray-light,
        'gray-lighter': $gray-lighter,
        'gray-lightest': $gray-lightest
    );

    @return $bootstrap-colors;
}
