// inc
@import "../../inc/inc";

/* ********** SUXESIV AG – BOOTSTRAP FIXES ********** */

// show scrollbar even if there is nothing to scroll (consistent page width across pages)
html {
    overflow-y: scroll;
}

// modal
.modal-open {
    padding-right: 0 !important;
}

/** custom color classes */
@each $property, $className in ('color':'text', 'background-color':'bg') {
    @each $name, $color in get_bootstrap_colors() {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                .#{$className}-color#{$infix}-#{$name} {
                    #{$property}: $color !important;
                }
            }
        }
    }
}

// inverse bg
.bg-inverse {
    color: $inverse-color;
    hr {
        background-color: $inverse-color;
    }
}

// btn
.btn,
button {
    cursor: pointer;
}

// fix font family for input / buttons
.btn,
button,
input,
textarea {
    font-family: $font-family-sans-serif;
}

// navbar
.navbar {
    @include media-breakpoint-down('xs') {
        .container {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

// sizing
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
                .m#{$abbrev}#{$infix}-#{$size} {
                    max-#{$prop}: $length !important;
                }
            }
        }
    }
}

.background-cover {
    @include background-cover();
}

// list
ul.list-inline,
ol.list-inline {
    list-style: none;

    li {
        display: inline;
    }
}
